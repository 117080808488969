<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer fluid class="mt-3" bg="white">
    <MDBCard>
      <MDBCardBody>
        <transition name="fade">
          <PageLoading v-if="PageLoading"></PageLoading>
        </transition>
        <transition name="fade">
          <AxiosLoading v-if="loading"></AxiosLoading>
        </transition>
        <MDBCardTitle></MDBCardTitle>
        <MDBRow class="mb-3">
          <MDBCol>
            <MDBInput
              :label="$t('ECOMMERCE.COMMON.SEARCH')"
              v-on:input="setSearchText"
            ></MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol class="mb-2 text-start" md="2">
            <div class="mb-3">
              <div>
                <label for="status" class="form-label text-dark">{{
                  $t("COMMON.STATUS")
                }}</label>
              </div>
              <select class="form-select" name="status" v-on:input="setStatusSelector">
                <option value="" selected>----------</option>
                <option value="1">{{ $t("COMMON.ENABLE") }}</option>
                <option value="0">{{ $t("COMMON.DISABLE") }}</option>
              </select>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBCardText>
          <MDBRow
            class="mb-3"
            v-for="(item, index) in exchangeRateList(Form)"
            :key="index"
          >
            <MDBCol md="5">
              <select class="form-select" disabled>
                <option>
                  {{ item.title }}
                </option>
              </select>
            </MDBCol>
            <MDBCol md="3">
              <MDBInput
                :label="$t('COMMON.RATE')"
                v-model="item.exchangeRate"
                disabled
              ></MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <div class="mb-3">
                <div class="form-switch mb-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="status"
                    name="status"
                    :checked="item.status"
                    @change="changeStatus(item)"
                  />
                  {{ $t("COMMON.ENABLE") + "/" + $t("COMMON.DISABLE") }}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  // MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import { ref, reactive } from "vue";
import PageLoading from "@/components/Elements/PageLoading";
import AxiosLoading from "@/components/Elements/AxiosLoading";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";

export default {
  name: "CurrenySettings",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    PageLoading,
    AxiosLoading,
  },
  setup() {
    const PageLoading = ref(true);
    const Form = reactive([]);
    const Currencies = reactive([]);
    const baseUrl = "/Admin/v1/currency";
    const loading = ref(false);
    const searchText = ref("");
    const statusSelector = ref("");
    const i18n = useI18n();

    // 取得手動匯率
    const getExchangeRate = async () => {
      return await ApiService.get(`${baseUrl}/online`).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;
          let index = 0;
          for (let i = responseData.length; i--; ) {
            Form[index] = {
              title: `${responseData[i].name}--${responseData[i].currency}`,
              currency: responseData[i].currency,
              exchangeRate: responseData[i].exchange_rate,
              status: responseData[i].status,
              uuid: responseData[i].uuid,
            };

            index++;
          }
        }
      });
    };
    // 關鍵字搜尋
    const setSearchText = (e) => {
      if (typeof e !== "undefined") {
        searchText.value = e.target.value;
      } else {
        searchText.value = "";
      }
    };
    // 狀態列表
    const setStatusSelector = (e) => {
      if (typeof e !== "undefined") {
        statusSelector.value = e.target.value;
      } else {
        statusSelector.value = "";
      }
    };
    // 匯率列表
    const exchangeRateList = (Form) =>
      Form.filter((item) => {
        let status = true;

        if (status && searchText.value !== "") {
          status = item.title.indexOf(searchText.value) !== -1;
        }

        if (status && statusSelector.value !== "") {
          status = item.status === +statusSelector.value;
        }

        return status;
      });
    // 更改狀態
    const changeStatus = (item) => {
      const choise = event.target.checked;
      const uuid = item.uuid;

      const sendData = {
        status: +choise,
      };

      return ApiService.update(`${baseUrl}/online`, uuid, sendData).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.STATUS")}${i18n.t("COMMON.EDIT")}${i18n.t(
              "COMMON.SUCCESS"
            )}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
          // 狀態修改後修改列表status值
          Form.map((item) => {
            if (item.uuid === uuid) item.status = +choise;

            return item;
          });
        }
      });
    };

    return {
      PageLoading,
      Form,
      Currencies,
      loading,
      getExchangeRate,
      setSearchText,
      setStatusSelector,
      exchangeRateList,
      changeStatus,
    };
  },
  mounted() {
    Promise.all([this.getExchangeRate()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
